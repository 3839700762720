import React from "react";
import Helmet from "../../components/Helmet";
import useContentful from "../../hooks/useContentful";
import { getListingsPage, getListedInstruments } from "../../contentful";
import marked from "marked";
import "./ForSalePageStyles.scss";
import PageTitle from "../../components/PageTitle";
import Gallery from "../../components/Gallery/Gallery";
import PayPalButton from "react-paypal-smart-button";
import { useSelector } from "react-redux";

const ForSalePage = () => {
  const content = useContentful(getListingsPage)?.content?.[0]?.fields;
  const list = useContentful(getListedInstruments)?.content;
  console.log(content);
  console.log(list);
  const { paypalClientId } = useSelector((state) => state);

  function filterRecentItems(items) {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    console.log(sixMonthsAgo);
    return items.filter((item) => {
      const itemDate = new Date(item.fields.date); // Assuming item.date is a valid date string
      return itemDate >= sixMonthsAgo;
    });
  }
  return (
    <div className='container'>
      <Helmet title='HKSNA | Pre Owned Instruments' />
      <PageTitle title={content?.title} />

      <div className='instrument-page-container'>
        {content?.description && (
          <section className='instrument-page-text' dangerouslySetInnerHTML={{ __html: marked(content.description) }} />
        )}
        <div className='instrument-page-container__payment'>
          <PayPalButton
            price={25}
            description={"Instrument listing: 6 months"}
            clientId={paypalClientId}
            currency='USD'
          />
        </div>
      </div>

      {filterRecentItems(list).map((item) => {
        const date = new Date(item.fields.date);
        return (
          <div className='instrument-card'>
            <div className='instrument-card__details'>
              <div className='instrument-card__text'>
                <span className='instrument-card__date'>{date?.toDateString()}</span>
                <span className='instrument-card__meta instrument-card__make'>{item.fields.make}</span>
                <span className='instrument-card__meta'>{item.fields?.builder}</span>
                <span className='instrument-card__meta'>{item.fields?.model}</span>
                <span className='instrument-card__meta'>{item.fields?.year}</span>
                <span className='instrument-card__meta instrument-card__price'>Offered at ${item.fields?.price}</span>

                {item.fields.description && (
                  <section
                    className='instrument-card__description'
                    dangerouslySetInnerHTML={{ __html: marked(item.fields.description) }}
                  />
                )}
                <div className='instrument-card__seller'>
                  <span className='instrument-card__meta'>Location: {item.fields?.location}</span>
                  <span className='instrument-card__meta'>
                    Email: <a href={`mailto:${item.fields.email}`}>{item.fields?.email}</a>
                  </span>
                  <span className='instrument-card__meta'>Tel: {item.fields?.phone}</span>
                </div>
              </div>
              {item.fields.photos && (
                <div className='instrument-card__images'>
                  <Gallery images={item.fields.photos} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ForSalePage;
