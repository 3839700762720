import React, { useState, useEffect, useMemo } from "react";

import "./Appbar.scss";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link, useLocation } from "react-router-dom";

import { ACTIONTYPES } from "../../store/ActionTypes";
import { IconButton, AppBar, Menu, Typography, MenuItem, Button } from "@material-ui/core";
import Facebook from "@material-ui/icons/Facebook";
import MenuIcon from "@material-ui/icons/Menu";
import Envelope from "@material-ui/icons/Mail";
import logo from "../../assets/logo.jpg";
import { ROUTES } from "../../constants/navigation";
import useFirebase from "../../hooks/useFirebase";
import { getCompetitions } from "../../contentful";

const Appbar = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [pubAnchorEl, setPubAnchorEl] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();
  const { user, paidUser, loginArea } = useSelector((state) => state);
  const { logout } = useFirebase();
  const [competitions, setCompetitions] = useState([]);

  useEffect(() => {
    getCompetitions().then((data) => {
      setCompetitions(data);
    });
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPubTag = Boolean(pubAnchorEl);
  const handlePubClick = (event) => {
    setPubAnchorEl(event.currentTarget);
  };
  const handlePubClose = () => {
    setPubAnchorEl(null);
  };

  const envelopeIcon = () => {
    if (!location.pathname.includes("contact") && !location.pathname.includes("membership")) {
      return (
        <IconButton onClick={() => dispatch({ type: ACTIONTYPES.TOGGLE_CONTACT_MODAL })}>
          <Envelope color='primary' />
        </IconButton>
      );
    }
  };

  return (
    <AppBar color='white'>
      <div className='appbar-container'>
        <div className='burger-container'>
          <div className='burger'>
            <IconButton onClick={() => dispatch({ type: ACTIONTYPES.TOGGLEDRAWER })}>
              <MenuIcon color='primary' />
            </IconButton>
          </div>
        </div>
        <Link to='/'>
          <img src={logo} />
        </Link>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: 220,
          }}
        >
          <a
            href='https://www.facebook.com/Historical-Keyboard-Society-of-North-America-167674393408920/'
            target='_blank'
          >
            <IconButton>
              <Facebook color='primary' />
            </IconButton>
          </a>
          {envelopeIcon()}
          {!user && loginArea && (
            <NavLink className='login-button' to='/login'>
              <Button variant='contained' color='primary'>
                Login
              </Button>
            </NavLink>
          )}
        </div>
      </div>
      <div className='nav'>
        <div className='inner-nav'>
          {ROUTES.map((route) => (
            <NavLink key={route.path} className='link' to={route.path}>
              {route.label}
            </NavLink>
          ))}
          {competitions.map((c) => (
            <NavLink key={c.fields.navigationLabel} className='link' to={`/competitions/${c.fields.navigationLink}`}>
              {c.fields.navigationLabel}
            </NavLink>
          ))}
          <span className='link ' onClick={handlePubClick}>
            Publications
          </span>
          <Menu anchorEl={pubAnchorEl} open={openPubTag} onClose={handlePubClose}>
            <MenuItem>
              <NavLink onClick={handlePubClose} className='dropdown-tag' to='/journal'>
                Early Keyboard Journal
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink onClick={handlePubClose} className='dropdown-tag' to='/journal-index'>
                Journal Index
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink onClick={handlePubClose} className='dropdown-tag' to='/newsletters'>
                Newsletter
              </NavLink>
            </MenuItem>
          </Menu>
          <NavLink onClick={handlePubClose} className='link' to='/pre_owned_instruments'>
            Pre-Owned Instruments{" "}
          </NavLink>
          {!paidUser && (
            <NavLink className='link' to='/membership'>
              Membership
            </NavLink>
          )}
          {paidUser && (
            <NavLink style={{ color: "green" }} className='link' to='/members_area'>
              Members Area
            </NavLink>
          )}
          <NavLink className='link' to='/contact'>
            Contact
          </NavLink>
          <form className='donate-button' action='https://www.paypal.com/donate' method='post' target='_blank'>
            <input type='hidden' name='hosted_button_id' value='6KW6VNVW32REA' />
            <div style={{ marginTop: -2, marginLeft: 8 }}>
              <Button type='submit' variant='outlined' color='primary'>
                Donate
              </Button>
            </div>
          </form>
        </div>

        <div className='auth-buttons'>
          {user && (
            <>
              <div className='user-button' onClick={handleClick}>
                {user && user.avatar ? (
                  <img src={user.avatar} />
                ) : user.name ? (
                  <>
                    <Typography>{user.name.split(" ")[0].slice(0, 1)}</Typography>
                    {user.name.split(" ").length > 1 && <Typography>{user.name.split(" ")[1].slice(0, 1)}</Typography>}
                  </>
                ) : null}
              </div>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem>
                  {" "}
                  <NavLink
                    style={{ textDecoration: "none" }}
                    // className='link'
                    to='/membership'
                  >
                    Manage Membership
                  </NavLink>
                </MenuItem>
                <MenuItem onClick={logout}>logout</MenuItem>
              </Menu>
            </>
          )}
        </div>
      </div>
    </AppBar>
  );
};

export default Appbar;
